import { LayoutDefault } from '@client/components/layout-default';
import { Avatar } from '@client/components/ui/avatar';
import { TypographyH1 } from '@client/components/ui/custom/typography-h1';
import { TypographyH2 } from '@client/components/ui/custom/typography-h2';
import { TypographyLarge } from '@client/components/ui/custom/typography-large';
import { AvatarFallback, AvatarImage } from '@radix-ui/react-avatar';
import { createFileRoute, Link, useRouter } from '@tanstack/react-router'
import { AlertTriangle, ArrowRightIcon, ArrowRightLeft, Calendar, Check, CirclePlusIcon, CopyIcon, CreditCard, Edit, Filter, Info, InfoIcon, LinkIcon, LogOut, MessageCircle, MessageSquare, PlusIcon, Send, UserIcon, X, XIcon } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from "@client/components/ui/popover"
import { Separator } from '@client/components/ui/separator';
import { Button } from '@client/components/ui/button';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Tabs, TabsTrigger, TabsContent, TabsList } from '@client/components/ui/tabs';
import { TypographyH3 } from '@client/components/ui/custom/typography-h3';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CheckIcon } from 'lucide-react';
import { toast } from 'sonner';
import { sleep } from '@client/lib/utils';
import { MoreVertical, Play, Trash2 } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@client/components/ui/dropdown-menu";
import { cn } from "@client/lib/utils";
import { formatBookingDate, getClassName, getDuration, getRelativeTimeFromNow, prettyErrorCode, prettyPrintTime, validateTime } from '@client/lib/helpers';
import { Switch } from '@client/components/ui/switch';
import { Select } from '@client/components/ui/custom/select';
import { CLASS_GROUPS, CLASSES, ClassId, DAYS_OF_WEEK, ErrorCode, MAX_INVITES_PER_ACCOUNT } from '@server/const';
import { useBooker } from '@client/hooks/use-booker';
import { useCalendar } from '@client/hooks/use-calendar';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@client/components/ui/card';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { useScreenSize } from '@client/lib/use-screen-size';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle, DrawerTrigger } from '@client/components/ui/drawer';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogScreenReaderInfo, DialogTitle } from '@client/components/ui/dialog';
import { useBilling } from '@client/hooks/use-billing';
import { useAuth } from '@client/hooks/use-auth';
import { Textarea } from '@client/components/ui/textarea';
import { trpc } from '@client/lib/trpc';
import { useTidio } from '@client/hooks/use-tidio';
import { MultiSelect } from '@client/components/ui/custom/multi-select';
import _ from 'lodash';
import { Label } from '@client/components/ui/label';
import { Input } from '@client/components/ui/input';
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@client/components/ui/command';
import moment from 'moment';
import { Job } from '@server/types';
import { TRPCClientError } from '@trpc/client';
import { id } from 'date-fns/locale';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@client/components/ui/accordion';

const JOBS = 'automated-bookings';
const CALENDAR_SYNC = 'calendar-sync';
const INVITE_FRIENDS = 'invite-friends';
const CONTACT_FORM_ID = 'contact-form';

const FILTER_OPTIONS: {
  label: string;
  value: string;
}[] = _.uniq(Object.values(CLASSES).map(c => c.tags).flat()).map(tag => ({
  label: tag,
  value: tag,
}));

const DAY_OPTIONS: {
  label: string;
  value: string;
}[] = Object.entries(DAYS_OF_WEEK).map(x => ({
  label: x[1],
  value: x[0],
}));

const CLASS_OPTIONS: {
  label: string;
  value: string;
  group?: string;
}[] = Object.entries(CLASSES).map(x => ({
  label: x[1].name,
  value: x[0],
  // group: x[1].category,
}));

const sortByFrom = (a: Job, b: Job) => {
  return a.from.localeCompare(b.from);
}

enum JobAction {
  RUN_NOW = 'run-now',
  DELETE = 'delete',
}

// const ContactModal = (props: {
//   open: boolean;
//   onOpenChange: (open: boolean) => void;
// }) => {
//   const { open, onOpenChange } = props;

//   const contactMutation = trpc.contact.useMutation();

//   const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     const formData = new FormData(e.target as HTMLFormElement);
//     const message = formData.get('message') as string;
//     const promise = contactMutation.mutateAsync({ message });
//     toast.promise(promise, {
//       loading: 'Sending message...',
//       success: 'Message sent',
//       error: 'Failed to send message',
//     });
//     await promise;
//     onOpenChange(false);
//   }, [contactMutation.mutateAsync, onOpenChange]);

//   return (
//     <Dialog open={open} onOpenChange={onOpenChange}>
//       <DialogContent>
//         <DialogHeader>
//           <DialogTitle>Send a message</DialogTitle>
//           <DialogDescription>Got a question? Ideas? Let me know and I'll see what I can do.</DialogDescription>
//         </DialogHeader>

//         <form id={CONTACT_FORM_ID} onSubmit={handleSubmit}>
//           <Textarea
//             name='message'
//             placeholder='Your message'
//             rows={5}
//           />
//         </form>
        
//         <DialogFooter>
//           <Button form={CONTACT_FORM_ID} disabled={contactMutation.isPending}>Send</Button>
//         </DialogFooter>
//       </DialogContent>
//     </Dialog>
//   )
// }

const InviteFriend = () => {
  const [open, setOpen] = useState(false);
  const { isMobile } = useScreenSize();
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { accountProfile } = Route.useLoaderData();
  const inviteFriendMutation = trpc.sendInvite.useMutation();
  const invitesSent = accountProfile.invitesSent ?? 0;
  const invitesRemaining = accountProfile.unlimitedInvites ? MAX_INVITES_PER_ACCOUNT : MAX_INVITES_PER_ACCOUNT - invitesSent;

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const email = formData.get('email') as string;
    const promise = inviteFriendMutation.mutateAsync({ toEmail: email });
    toast.promise(promise, {
      loading: 'Sending invite...',
      success: 'Invite sent',
      error: err => {
        if(err instanceof TRPCClientError) {
          return err.message;
        } else {
          const message = (err as Error).message ?? '';
          return prettyErrorCode(message as ErrorCode, 'Failed to send invite');
        }
      },
    });
    await promise;
    setOpen(false);
    await router.invalidate();
  }, [inviteFriendMutation.mutateAsync, router.invalidate]);

  const renderedBtn = useMemo(() => {
    return (
      <Button variant='secondary' size={'sm'} className={cn(`flex items-center gap-2 text-xs cursor-pointer`, {
        'bg-highlight/50 hover:bg-highlight/70': true
      })} disabled={invitesRemaining <= 0}>
        <Send className='w-3 h-3' />
        {/* {`${invitesRemaining} ${invitesRemaining === 1 ? 'invite' : 'invites'} remaining`} */}
        Invite a friend
      </Button>
    )
  }, [invitesRemaining]);

  const renderedContent = useMemo(() => {
    return (
      <form onSubmit={handleSubmit} className='flex flex-col gap-2 p-4'>
        <div className='flex items-center gap-2'>
          <Send className='w-3 h-3' />
          <TypographyP className='text-sm font-semibold mt-0'>Invite a friend</TypographyP>
        </div>
        <TypographyMuted>Make sure to use the email they use to log into JH.</TypographyMuted>
        <Input name='email' placeholder='Email' type='email' required autoComplete={'off'} autoFocus className='mt-3' />
        <Button type='submit' className='max-w-[100px]'>Send</Button>
      </form>
    )
  }, [handleSubmit]);

  if (isMobile) {
    const FULL_HEIGHT = true;
    return (
      <Drawer open={open} onOpenChange={setOpen} shouldScaleBackground={false}>
        <DrawerTrigger asChild>{renderedBtn}</DrawerTrigger>
        <DrawerContent
          addClose
          className={cn(`pb-4 min-h-[200px]`, {
            'min-h-[200px]': !FULL_HEIGHT,
            'h-[90dvh]': FULL_HEIGHT,
          })}>
          <DialogScreenReaderInfo title={'Send Invite'} />
          {renderedContent}
        </DrawerContent>
      </Drawer>
    );
  } else {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{renderedBtn}</PopoverTrigger>
        <PopoverContent
          align={'end'}
          alignOffset={0}
          side={'bottom'}
          sideOffset={10}
          avoidCollisions={true}
          className={cn('min-w-[320px] p-0')}>
          {renderedContent}
        </PopoverContent>
      </Popover>
    );
  }
}

const ClassCommand = (props: { filters: string[]; value: string | undefined; onSelect: (value: string) => void; inputRef: React.RefObject<HTMLInputElement> }) => {
  const { filters, value, onSelect, inputRef } = props;
  const options = useMemo<Array<{
    // group?: string;
    tags: string[];
    value: string;
    label: string;
  }>>(() => {
    return [
      ...Object.entries(CLASS_GROUPS).map(x => ({
        value: x[0],
        label: x[1].name,
        tags: x[1].tags,
      })),
      ...Object.entries(CLASSES).map(x => ({
        value: x[0],
        label: x[1].name,
        tags: x[1].tags,
      })),
    ].filter(c => filters.length > 0 ? filters.every(filter => c.tags.includes(filter)) : true);
  }, [filters]);

  const selectedOpt = useMemo(() => {
    return options.find(opt => opt.value === value);
  }, [options, value]);

  return (
    <Command onValueChange={onSelect} className='rounded-lg border relative'>
      <CommandInput placeholder={ selectedOpt ? '' : 'Search classes...'} ref={inputRef} disabled={!!selectedOpt} className='disabled:cursor-default' />
      { selectedOpt && (
        <div className='absolute top-1 left-1'>
          <div className='flex items-center gap-2 bg-secondary p-2 rounded-lg text-sm font-medium'>
            <span>{selectedOpt.label}</span>
            <button className='p-1' onClick={() => onSelect('')}>
              <X className='w-3 h-3' />
            </button>
          </div>
        </div>
      ) }
      <CommandList>
        <CommandEmpty>No classes found.</CommandEmpty>
        {options.map(opt => (
          <CommandItem key={opt.value} value={opt.value} onSelect={() => onSelect(opt.value)}>
            <Check
              className={cn(
                "mr-2 h-4 w-4",
                value === opt.value ? "opacity-100" : "opacity-0"
              )}
            />
            {opt.label}
          </CommandItem>
        ))}
      </CommandList>
    </Command>
  )
}

const SectionCard = (props: PropsWithChildren) => {
  return (
    <Card className='shadow-none border-0 sm:border'>
      {props.children}
    </Card>
  )
}

const SectionCardHeader = (props: PropsWithChildren) => {
  return (
    <CardHeader className='px-0 sm:px-4'>
      {props.children}
    </CardHeader>
  )
}

const SectionCardContent = (props: PropsWithChildren) => {
  return (
    <CardContent className='px-0 sm:px-4'>
      {props.children}
    </CardContent>
  )
}

const UserMenu = (props: {
  onOpenContactModal: () => void;
}) => {
  const { onOpenContactModal } = props;
  const tidio = useTidio();
  const [menuOpen, setMenuOpen] = useState(false);
  const { isMobile } = useScreenSize();
  const router = useRouter();
  const { logout } = useAuth(router.invalidate);
  const { accountProfile } = Route.useLoaderData();
  const { goToBillingPortal } = useBilling();
  const firstLetterOfEmail = accountProfile.email.charAt(0).toUpperCase();

  const handleContact = () => {
    tidio.showTidio();
    tidio.openTidio();
  }

  const renderedAvatar = useMemo(() => {
    return (
      <Avatar className='cursor-pointer' onClick={() => setMenuOpen(true)}>
        {/* <AvatarImage src={'/static/avatar-placeholder.png'} /> */}
        <AvatarFallback className='flex items-center justify-center w-full'>
          <div className='w-full h-full bg-gradient-to-r from-primary/90 to-primary/80 flex items-center justify-center text-primary-foreground text-lg font-normal'>
            {firstLetterOfEmail}
          </div>
          {/* <UserIcon className='w-6 h-6' /> */}
        </AvatarFallback>
      </Avatar>
    )
  }, [firstLetterOfEmail]);

  const renderedContent = useMemo(() => {
    return (
      <div>
        <div className="grid gap-4 p-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Account</h4>
            <p className="text-xs text-muted-foreground">
              { accountProfile.email }
            </p>
            {/* <InviteFriend /> */}
            {/* <p className="text-xs text-muted-foreground flex items-center gap-2">
              <Send className='w-3 h-3' />
                2 invites remaining
            </p> */}
            {/* <Button variant='secondary' className='justify-start text-xs' size={'sm'}>
              <Send className='w-3 h-3 mr-2' />
                2 invites remaining
            </Button> */}
          </div>
        </div>
        <Separator />
        <div className='flex flex-col gap-1 p-1'>
          {/* <Button variant='ghost' className='justify-start'>
            Invite
          </Button> */}
          { accountProfile.billing.subscriptionId ? (
            <Button variant='ghost' className='justify-start' onClick={goToBillingPortal}>
              <CreditCard className='w-4 h-4 mr-2' />
              Billing
            </Button>
          ) : null }
          <Button variant='ghost' className='justify-start' onClick={handleContact}>
            <MessageCircle className='w-4 h-4 mr-2' />
            Get Help
          </Button>
          <Button variant='ghost' className='justify-start' onClick={logout}>
            <LogOut className='w-4 h-4 mr-2' />
            Logout
          </Button>
          <AdminImpersonate />
        </div>
      </div>
    )
  }, [accountProfile]);

  if (isMobile) {
    const FULL_HEIGHT = true;
    return (
      <Drawer open={menuOpen} onOpenChange={setMenuOpen} shouldScaleBackground={false}>
        <DrawerTrigger>{renderedAvatar}</DrawerTrigger>
        <DrawerContent
          addClose
          className={cn(`pb-4 min-h-[200px]`, {
            'min-h-[200px]': !FULL_HEIGHT,
            'h-[90dvh]': FULL_HEIGHT,
          })}>
          <DialogScreenReaderInfo title={'Account Menu'} />
          {renderedContent}
        </DrawerContent>
      </Drawer>
    );
  } else {
    return (
      <Popover open={menuOpen} onOpenChange={setMenuOpen}>
        <PopoverTrigger>{renderedAvatar}</PopoverTrigger>
        <PopoverContent
          align={'end'}
          alignOffset={0}
          side={'bottom'}
          sideOffset={10}
          avoidCollisions={true}
          className={cn('min-w-[200px] p-0')}>
          {renderedContent}
        </PopoverContent>
      </Popover>
    );
  }
}

const AdminImpersonate = () => {

  const { session: { isAdmin, isImpersonating } } = Route.useLoaderData();
  const [open, setOpen] = useState(false);

  const getAltEmailsQuery = trpc.getAltEmails.useQuery(undefined,  {
    enabled: open,
  });

  const impersonateMutation = trpc.impersonate.useMutation();
  const stopImpersonatingMutation = trpc.stopImpersonating.useMutation();

  const options = useMemo(() => {
    return getAltEmailsQuery.data?.map(email => ({
      value: email,
    })) ?? [];
  }, [getAltEmailsQuery.data]);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleSelect = useCallback(async (value: string | undefined) => {
    if (!value) {
      return;
    }
    setOpen(false);
    await impersonateMutation.mutateAsync(value);
    refreshPage();
  }, [impersonateMutation]);

  const stopImpersonating = useCallback(async () => {
    await stopImpersonatingMutation.mutateAsync();
    refreshPage();
  }, [stopImpersonatingMutation]);

  if(isImpersonating) {
    return (
      <Button variant='ghost' className='justify-start' onClick={stopImpersonating} disabled={stopImpersonatingMutation.isPending}>
        <X className='w-4 h-4 mr-2' />
        Stop Impersonating
      </Button>
    )
  }

  if(!isAdmin) {
    return null;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant='ghost' className='justify-start'>
          <ArrowRightLeft className='w-4 h-4 mr-2' />
          Change Account
        </Button>
      </PopoverTrigger>
      <PopoverContent align='start'>
        <div>
          <Select
            options={options}
            onChange={handleSelect}
            loading={getAltEmailsQuery.isLoading}
          />
        </div>
      </PopoverContent>
    </Popover>
  )
}

const Header = () => {
  const [contactModalOpen, setContactModalOpen] = useState(false);
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex justify-between items-center gap-4'>
        <TypographyP className='font-semibold'>JH Booker</TypographyP>
        <div className='flex gap-4'>
          <InviteFriend />
          <UserMenu onOpenContactModal={() => setContactModalOpen(true)} />
          {/* <ContactModal open={contactModalOpen} onOpenChange={setContactModalOpen} /> */}
        </div>
      </div>
      {/* <div className='mr-auto sm:hidden'>
        <InviteFriend />
      </div> */}
    </div>
  )
}

const AddPaymentMethod = () => {
  const { accountProfile } = Route.useLoaderData();
  const router = useRouter();
  const { goToCheckout } = useBilling();

  if(accountProfile.billing.subscriptionId || accountProfile.billing.exempt) {
    return null;
  }

  const daysTillTrialEnd = !accountProfile.active ? 0 : Math.ceil(moment(accountProfile.billing.trialEnd).diff(moment(), 'days', true));

  return (
    <div className='border-highlight border-2 bg-highlight/50 p-4 rounded-md'>
      { daysTillTrialEnd === 0 ? (
         <TypographyP className='text-sm'>Your account is no longer active. Add a payment method to continue using the service.</TypographyP>
      ) : (
         <TypographyP className='text-sm'>Your account will deactivate in {daysTillTrialEnd} {daysTillTrialEnd === 1 ? 'day' : 'days'}. Add a payment method to continue using the service.</TypographyP>
      ) }
      <Button size='sm' variant='link' className='pl-0 mt-2' onClick={goToCheckout}>
        <CreditCard className='w-4 h-4 mr-2' />
        Add Payment Method
        {/* <ArrowRightIcon className='w-4 h-4 ml-2' /> */}
      </Button>
    </div>
  )
}

const CalendarSection = () => {
  const { accountProfile } = Route.useLoaderData();
  const [copied, setCopied] = useState(false);
  const router = useRouter();
  const { toggleCalendar } = useCalendar(router.invalidate);
  const calendarEnabled = !!accountProfile.calendarSync?.enabled;

  const handleCopy = async () => {
    if (accountProfile.calendarSync) {
      const url = `https://jh-booker.com/feed/${accountProfile.calendarSync.icsFeedId}/calendar.ics`;
      await navigator.clipboard.writeText(url);
      toast.success('Copied to clipboard', {
        duration: 1000
      });
      setCopied(true);
      await sleep(2000);
      setCopied(false);
    }
  };

  return (
    <SectionCard>
      <SectionCardHeader>
        <CardTitle>Calendar Feed</CardTitle>
        <CardDescription>Automatically pull your bookings from the JH portal and make them available via a shareable calendar link.</CardDescription>
      </SectionCardHeader>
      <SectionCardContent>
      <div className='flex items-center gap-3'>
        <Switch checked={calendarEnabled} onCheckedChange={toggleCalendar} />
        <TypographyP className='text-sm'>Enable</TypographyP>
      </div>
      {calendarEnabled && (
        <div className='mt-6'>
          {/* <div className='my-4 p-4 rounded-md bg-muted flex items-start gap-2'>
            <InfoIcon className='w-5 h-5 text-primary flex-shrink-0' />
            <TypographyP className='text-sm italic text-muted-foreground mt-0'>
              Bookings are synced every hour, however, depending on which calendar you are adding the feed to it may be slower.
              Google is particuarly slow, averaging every 7 hours.</TypographyP>
          </div> */}
          <Button variant='secondary' className='flex items-center gap-2 mt-2' onClick={handleCopy}>
            {copied ? <CheckIcon className='w-4 h-4 text-green-500' /> : <LinkIcon className='w-4 h-4' />}
            Copy Feed URL
          </Button>
          { accountProfile.calendarSync.lastQueriedAt instanceof Date && <div className='flex items-center gap-2 mt-2'>
            {/* <InfoIcon className='w-3 h-3 text-muted-foreground' /> */}
            <TypographyP className='text-muted-foreground text-xs italic'>{`Last synced: ${getRelativeTimeFromNow(accountProfile.calendarSync.lastQueriedAt)}`}</TypographyP>
          </div>}
        </div>
      )}

      <Accordion type='multiple' className='-mb-4'>
        <AccordionItem value='instructions' className='border-b-0'>
          <AccordionTrigger className='font-semibold text-sm'>
            <div className='flex items-center'>
              <Info className='w-4 h-4 mr-2' />
              Setup Instructions
            </div>
          </AccordionTrigger>
          <AccordionContent className='font-sm'>
            <TypographyH3>Google Calendar</TypographyH3>
            <ul className='list-decimal mt-2'>
              <li>Copy the Feed URL above and go to Google Calendar.</li>
              <li>On the left hand panel, click on the plus icon next to "Other calendars" and select "From URL".</li>
              <li>Paste the Feed URL into the "URL of calendar" field.</li>
              <li>Click "Add calendar" and you should see your bookings appear.</li>
            </ul>
            <img src='/static/help-gcal-1.png' alt='Google Calendar Instructions' className='mt-4 rounded-md max-w-64' />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      </SectionCardContent>
    </SectionCard>
  )
}

const AutomatedBookingsSection = (props: {
  onOpenJobModal: () => void;
}) => {
  const { onOpenJobModal } = props;
  const { jobs, accountProfile } = Route.useLoaderData();
  const router = useRouter();
  const { runningJobs, addJob, deleteJob, runJob, toggleAutomatedBookings } = useBooker(router.invalidate);
  const automatedBookingsEnabled = !!accountProfile.autoBooker?.enabled;

  const getStatusColor = useCallback((status: string) => {
    switch (status) {
      case 'healthy':
        return 'bg-green-500';
      case 'warning':
        return 'bg-orange-500';
      case 'unhealthy':
        return 'bg-destructive';
      default:
        return 'bg-gray-500';
    }
  }, []);

  const handleJobAction = useCallback((jobId: string, action: string | undefined) => {
    if (!action) {
      return;
    }
    switch (action) {
      case JobAction.RUN_NOW:
        runJob(jobId);
        break;
      case JobAction.DELETE:
        deleteJob(jobId);
        break;
      default:
        break;
    }
  }, [runJob, deleteJob]);

  const jobsByDay = useMemo(() => {
    return _.groupBy(jobs, 'day');
  }, [jobs]);

  return (
    <SectionCard>
      <SectionCardHeader>
        <CardTitle>Automated Bookings</CardTitle>
        <CardDescription>Setup jobs to automatically book your classes each week.</CardDescription>
      </SectionCardHeader>
      <SectionCardContent>
        <div className='flex items-center gap-3'>
          <Switch checked={automatedBookingsEnabled} onCheckedChange={toggleAutomatedBookings} />
          <TypographyP className='text-sm'>Enable</TypographyP>
        </div>
        {automatedBookingsEnabled && (
          <div className='mt-6'>
            <button onClick={onOpenJobModal} className='w-full flex justify-center items-center border-dashed border-2 border-border rounded-md p-4 text-muted-foreground hover:text-foreground transition-colors duration-200'>
              <div className='flex items-center gap-2'>
                <CirclePlusIcon className='w-4 h-4' />
                <TypographyP className='text-xs font-semibold mt-[1px]'>Create</TypographyP>
              </div>
            </button>
            <div className='flex flex-col gap-4 mt-4'>
              { Object.entries(jobsByDay).sort().map(x => {
                return (
                  <div key={x[0]}>
                    <TypographyP className='text-sm font-semibold my-2'>{moment().isoWeekday(parseInt(x[0])).format('dddd')}</TypographyP>
                    <div className='flex flex-col gap-4'>
                      { x[1].sort(sortByFrom).map(job => (
                        <div key={job.id} className={cn('rounded-md border')}>
                          <div className='flex justify-between items-center py-2 px-4 bg-muted rounded-t-md'>
                            <div className='flex items-center gap-2'>
                              <div className={cn('w-[10px] h-[10px] rounded-full mr-[6px]', getStatusColor(job.status))} />
                              <div className='font-semibold'>{getClassName(job)}</div>
                              <div className='text-sm font-semibold'>@ {prettyPrintTime(job)}</div>
                            </div>
                            <Select
                              asBtn
                              noDrawer
                              asBtnProps={{
                                variant: 'ghost',
                                size: 'icon',
                                disabled: runningJobs[job.id],
                              }}
                              placeholder=''
                              placeholderIcon={<MoreVertical className="w-4 h-4" />}
                              onChange={(value) => handleJobAction(job.id, value)}
                              options={[
                                { value: JobAction.RUN_NOW, label: 'Run now', icon: <Play className="w-4 h-4" /> },
                                { value: JobAction.DELETE, label: 'Delete', icon: <Trash2 className="w-4 h-4" /> },
                              ]}
                              disableSearch
                              popoverOpts={{
                                align: 'end',
                                className: 'w-32',
                              }}
                              disabled={runningJobs[job.id]}
                            />
                          </div>
                          <Separator />
                          <div className='mt-2 text-sm py-2 px-4 flex flex-col gap-1'>
                            <p><span className='text-muted-foreground font-semibold'>Last Ran: </span> {job.lastRunAt instanceof Date ? getRelativeTimeFromNow(job.lastRunAt) : '-'}</p>
                            <p><span className='text-muted-foreground font-semibold'>Next Run: </span> {job.nextRunAt instanceof Date ? getRelativeTimeFromNow(job.nextRunAt) : '-'}</p>
                            <p><span className='text-muted-foreground font-semibold'>Next Booking: </span> {job.nextBookingDate instanceof Date ? formatBookingDate(job.nextBookingDate) : '-'}</p>
                          </div>

                          {job.statusMessage && (
                            <div>
                              <Separator />
                              <div className='p-2 pl-4 rounded-b-md flex items-center gap-2'>
                                { job.status === 'warning' && <MessageCircle className='w-4 h-4' /> }
                                { job.status === 'unhealthy' && <MessageSquare className='w-4 h-4' /> }
                                <p className='text-sm'>{prettyErrorCode(job.statusMessage as ErrorCode)}</p>
                                {/* { job.status === 'unhealthy' && <span className='text-xs italic'>- Temporarily disabled</span> } */}
                                <Button className='ml-auto' variant='ghost' onClick={() => runJob(job.id)} disabled={runningJobs[job.id]}>Retry</Button>
                              </div>
                            </div>
                          )}
                        </div>
                      )) }
                    </div>
                  </div>
                )
              }) }
            </div>
          </div>
        )}
        
      </SectionCardContent>
    </SectionCard>
  )
}

const AddJob = (props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  const { open, onOpenChange } = props;
  const commandInputRef = useRef<HTMLInputElement>(null);
  const { accountProfile } = Route.useLoaderData();
  const router = useRouter();
  const { addJob } = useBooker(router.invalidate);
  const { isMobile } = useScreenSize();
  const [step, setStep] = useState<number>(1);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedClass, setSelectedClass] = useState<string | undefined>();
  const [selectedDay, setSelectedDay] = useState<string | undefined>();
  const [fromTime, setFromTime] = useState<string>('');
  const [toTime, setToTime] = useState<string>('');
  const [children, setChildren] = useState<string[]>([]);
  const [adults, setAdults] = useState<string[]>([]);
  const [crechePassword, setCrechePassword] = useState<string>(accountProfile.crechePassword || '');
  const [crechePasswordOpen, setCrechePasswordOpen] = useState<boolean>(false);

  const updateCrechePasswordMutation = trpc.automatedBookings.updateCrechePassword.useMutation();

  const reset = useCallback(() => {
    setStep(1);
    setSelectedFilters([]);
    setSelectedClass(undefined);
    setSelectedDay(undefined);
    setFromTime('');
    setToTime('');
    setChildren([]);
    setAdults([]);
  }, []);

  useEffect(() => {
    if(!open) {
      reset();
    }
  }, [open, reset]);

  const isCreche = selectedClass === ClassId.CRECHE_KIDS_CLUB;

  const childrenOpts = useMemo(() => accountProfile.childNames.map(x => ({
    value: x,
  })), [accountProfile.childNames]);

  const adultsOpts = useMemo(() => accountProfile.adultNames.map(x => ({
    value: x,
  })), [accountProfile.adultNames]);

  // const classOptions = useMemo(() => {
  //   const defaultOptions = Object.entries(CLASSES).filter(x => {
  //     if(!selectedFilters.length) {
  //       return true;
  //     }
  //     return selectedFilters.every(filter => x[1].tags.includes(filter as any));
  //   }).map(x => ({
  //     label: x[1].name,
  //     value: x[0],
  //   }));

  //   const groupOptions = Object.entries(CLASS_GROUPS).filter(x => {
  //     return selectedFilters.every(filter => x[1].tags.includes(filter as any));
  //   }).map(x => ({
  //     label: x[1].name,
  //     value: x[0],
  //   }));

  //   return [...groupOptions, ...defaultOptions];
  // }, [selectedFilters]);

  const handleFilterChange = useCallback((value: string[]) => {
    setSelectedFilters(value);
    commandInputRef.current?.focus();
  }, [setSelectedFilters]);

  // const selectedClassOption = useMemo(() => {
  //   return classOptions.find(o => o.value === selectedClass);
  // }, [classOptions, selectedClass]);

  useEffect(() => {
    if(step === 1 && !!selectedClass) {
      setStep(current => current + 1);
    }
  }, [selectedClass, setStep]);

  const handleTimeChange = useCallback((time: 'from' | 'to') => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (time === 'from') {
        setFromTime(value);
      } else {
        setToTime(value);
      }
    }
  }, []);

  const handleChangeCrechePassword = useCallback(() => {
    setCrechePasswordOpen(true);
  }, []);

  const handleSaveCrechePassword = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const password = (formData.get('crechePassword') || '') as string;
    const promise = updateCrechePasswordMutation.mutateAsync({ password });
    toast.promise(promise, {
      success: 'Creche password updated',
      error: 'Failed to update creche password',
    });
    await promise;
    setCrechePasswordOpen(false);
    setCrechePassword(password);
  }, [crechePassword, updateCrechePasswordMutation]);


  const handleSubmit = useCallback(async () => {
    await addJob({
      classId: selectedClass as any,
      day: selectedDay!,
      from: fromTime!,
      to: toTime!,
      childNames: children,
      adultNames: adults,
    });
    onOpenChange(false);
    reset();
  }, [children, adults, selectedClass, selectedDay, fromTime, toTime, addJob, reset, onOpenChange]);

  const step1 = useMemo(() => {
    return (
      <div className='mt-2 flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col sm:flex-row sm:items-center gap-2'>
            {/* <Label className='text-xs text-muted-foreground font-semibold'>Filters</Label> */}
            <Filter className='w-4 h-4' />
            <MultiSelect
              options={FILTER_OPTIONS}
              selected={FILTER_OPTIONS.filter(o => selectedFilters.includes(o.value))}
              onChange={handleFilterChange}
              optionsAsBtns
            />
          </div>
        </div>
        
        <div>
          {/* <Select
            options={classOptions}
            selected={selectedClassOption}
            onChange={setSelectedClass}
            placeholder='Select a class'
          /> */}
          <ClassCommand value={selectedClass} inputRef={commandInputRef} filters={selectedFilters} onSelect={setSelectedClass} />
        </div>
        { !!selectedClass && <ButtonGroup>
          <Button onClick={() => setStep(current => current + 1)}>Next</Button>
        </ButtonGroup>}
      </div>
    )
  }, [step, setStep, selectedFilters, selectedClass]);

  const step2 = useMemo(() => {
    const requiresToTime = !!selectedClass && !!(getDuration({ classId: selectedClass as any }));
    const { valid: fromValid } = validateTime(fromTime);
    const { valid: toValid } = validateTime(toTime);
    const canProceed = !!fromTime && fromValid && (!requiresToTime || !!toTime && toValid);
    return (
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            {/* <Label className='text-sm block'>Day of Week</Label> */}
            <Select
              options={DAY_OPTIONS}
              selected={DAY_OPTIONS.find(o => o.value === selectedDay)}
              onChange={setSelectedDay}
              placeholder='Select a day'
              optionsAsBtns
            />
          </div>
          { selectedDay && <div className='flex flex-col gap-2'>
            <Label className='text-sm block'>{ requiresToTime ? 'From' : 'At' }</Label>
            <Input
              type='text'
              value={fromTime}
              placeholder='HH:MM'
              onChange={handleTimeChange('from')}
            />
            { !requiresToTime && <TypographyMuted className='text-xs italic'>Make sure you use the exact time of the class. i.e 12:10</TypographyMuted> }
          </div>}
          { selectedDay && requiresToTime && (
            <div className='flex flex-col gap-2'>
              <Label className='text-sm block'>To</Label>
              <Input
                type='text'
                value={toTime}
                placeholder='HH:MM'
                onChange={handleTimeChange('to')}
              />
            </div>
          ) }
          <ButtonGroup>
            <Button variant='secondary' onClick={() => setStep(current => current - 1)}>Back</Button>
            { !!canProceed && <Button onClick={() => setStep(current => current + 1)}>Next</Button>}
          </ButtonGroup>
        </div>
    )
  }, [step, setStep, selectedDay, fromTime, toTime, selectedClass]);

  const step3 = useMemo(() => {
    const crecheValid = !isCreche || !!crechePassword;
    const hasSelectedSomeone = !!children.length || !!adults.length;
    const canProceed = hasSelectedSomeone && crecheValid;
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <Label className='text-sm block'>Children?</Label>
          <MultiSelect
            options={childrenOpts}
            selected={childrenOpts.filter(o => children.includes(o.value))}
            onChange={setChildren}
            placeholder='Select children'
            optionsAsBtns
          />
        </div>
        <div className='flex flex-col gap-2'>
          <Label className='text-sm block'>Adults?</Label>
          <MultiSelect
            options={adultsOpts}
            selected={adultsOpts.filter(o => adults.includes(o.value))}
            onChange={setAdults}
            placeholder='Select adults'
            optionsAsBtns
          />
        </div>
        { isCreche && hasSelectedSomeone && (
          <div className='mt-4'>
            <Popover open={crechePasswordOpen} onOpenChange={setCrechePasswordOpen}>
            { crechePassword ? (
              <>
                <Label className='text-sm block'>Creche Password</Label>
                <div className='flex items-center gap-2'>
                  { crechePassword }
                  <PopoverTrigger asChild>
                    <Button variant='ghost' size='icon' onClick={() => setCrechePasswordOpen(true)}><Edit className='w-4 h-4' /></Button>
                  </PopoverTrigger>
                </div>
              </>
            ) : (
              <PopoverTrigger asChild>
                <Button size='sm' variant={'default'} onClick={handleChangeCrechePassword}>Set Creche Password</Button>
              </PopoverTrigger>
            ) }
            <PopoverContent align='start'>
              <form onSubmit={handleSaveCrechePassword}>
                <div className='flex flex-col gap-2'>
                  <Label className='text-sm block'>Creche Password</Label>
                  <div className='flex flex-col sm:flex-row sm:items-center gap-2'>
                    <Input type='text' defaultValue={crechePassword} name='crechePassword' />
                    <Button size='icon' variant='default' className='flex-shrink-0 min-w-[60px]' type='submit'>Save</Button>
                  </div>
                </div>
              </form>
            </PopoverContent>
            </Popover>
          </div>
        ) }
        <ButtonGroup>
          <Button variant='secondary' onClick={() => setStep(current => current - 1)}>Back</Button>
          { canProceed && <Button onClick={handleSubmit}>Submit</Button>}
        </ButtonGroup>
      </div>
    )
  }, [step, setStep, children, adults, handleSubmit, isCreche, handleChangeCrechePassword, crechePassword, crechePasswordOpen]);

  const renderedContent = useMemo(() => {
    return (
      <>
        {step === 1 && step1}
        {step === 2 && step2}
        {step === 3 && step3}
      </>
    )
  }, [step, step1, step2, step3]);

  const title = useMemo(() => {
    if(step === 1) {
      return 'Select Class';
    }
    if(step === 2) {
      return 'Select Day and Time';
    }
    if(step === 3) {
      return 'Who\'s going?';
    }
    return 'Create Automated Booking';
  }, [step]);

  const description = `Step ${step} of 3`;
  // const description = useMemo(() => {
  //   return step === 1 ? 'Select a class and filters to automatically book your class each week.' : step === 2 ? 'Select a day and time to automatically book your class each week.' : 'Create Automated Booking';
  // }, [step]);

  return isMobile ? (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent addClose className={cn(`p-2 pb-4 min-h-[200px] h-[95dvh]`)}>
        <DialogScreenReaderInfo title={title} />
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          {/* <DrawerDescription>
            {description}
          </DrawerDescription> */}
        </DrawerHeader>
        <div className='mt-2 p-1 pb-4'>
          { renderedContent }
        </div>
      </DrawerContent>
    </Drawer>
  ) : (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className=''>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {/* <DialogDescription>
            {description}
          </DialogDescription> */}
        </DialogHeader>
        { renderedContent }
      </DialogContent>
    </Dialog>
  )
}

const BlurIdNotActive = (props: PropsWithChildren<{ active: boolean }>) => {
  const { active, children } = props;
  return active ? children : <div className='blur-sm pointer-events-none'>{children}</div>;
}

const Home = () => {
  const [jobOpen, setJobOpen] = useState(false);
  const { accountProfile } = Route.useLoaderData();
  const { goToCheckout, goToBillingPortal } = useBilling();
  return (
    <LayoutDefault>
      
      <Header />

      <div className='flex flex-col gap-6 mt-6'>
        <AddPaymentMethod />

        <BlurIdNotActive active={accountProfile.active}>
          <CalendarSection />
          <AutomatedBookingsSection onOpenJobModal={() => setJobOpen(true)} />
        </BlurIdNotActive>
      </div>

      <AddJob open={jobOpen} onOpenChange={setJobOpen} />

    </LayoutDefault>
  )
}

export const Route = createFileRoute('/_authenticated/')({
  component: Home,
  loader: async ({ context }) => {
    const [jobs, accountProfile] = await Promise.all([
      context.trpcUtils.automatedBookings.listJobs.fetch(),
      context.trpcUtils.getAccountProfile.fetch(),
    ]);
    return {
      jobs,
      session: context.session,
      accountProfile,
    };
  },
})

import  _ from 'lodash';
import { Duration } from './types';
import { config } from './config';

export const TZ_LONDON = 'Europe/London';
export const PORTAL_URL = 'https://portal.jesseshouse.co.uk/';
export const JAEGOS_PORTAL_URL = 'https://portal.jaegoshouse.co.uk/';
export const MAX_CONCURRENT_JOBS = 10;
export const ADMIN_EMAIL = 'helloturnbulls@gmail.com';
export const MAX_INVITES_PER_ACCOUNT = 2;

export enum ErrorCode {
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  ACCOUNT_ALREADY_EXISTS = 'ACCOUNT_ALREADY_EXISTS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  PERSON_NOT_AVAILABLE = 'PERSON_NOT_AVAILABLE',
  IMPLEMENTION_ERROR = 'IMPLEMENTION_ERROR',
  CLASS_NOT_FOUND_ON_DAY = 'CLASS_NOT_FOUND_ON_DAY',
  TIME_NOT_FOUND = 'TIME_NOT_FOUND',
  FULLY_BOOKED = 'FULLY_BOOKED',
  BOOKING_FAILED = 'BOOKING_FAILED',
  PAGE_FAILED_TO_LOAD = 'PAGE_FAILED_TO_LOAD',
  SERVER_ERROR = 'SERVER_ERROR',
  PORTAL_ERROR = 'PORTAL_ERROR',
  CRECHE_PASSWORD_NOT_SET = 'CRECHE_PASSWORD_NOT_SET',
  CRECHE_DROP_OFF_ADULT_NOT_AVAILABLE = 'CRECHE_DROP_OFF_ADULT_NOT_AVAILABLE',
  CRECHE_PICK_UP_ADULT_NOT_AVAILABLE = 'CRECHE_PICK_UP_ADULT_NOT_AVAILABLE',
  DAY_NOT_YET_OPEN_TO_BOOK = 'DAY_NOT_YET_OPEN_TO_BOOK',
  CLASHES_WITH_ANOTHER_BOOKING = 'CLASHES_WITH_ANOTHER_BOOKING',
  INVITE_LIMIT_REACHED = 'INVITE_LIMIT_REACHED',
  INVITE_NOT_FOUND = 'INVITE_NOT_FOUND',
  INVITE_ALREADY_REDEEMED = 'INVITE_ALREADY_REDEEMED',
  CLASS_REQUIRES_PAYMENT = 'CLASS_REQUIRES_PAYMENT',
}

export const RETRYABLE_ERROR_CODES: Array<string> = [
  ErrorCode.PERSON_NOT_AVAILABLE,
  ErrorCode.TIME_NOT_FOUND,
  ErrorCode.FULLY_BOOKED,
  ErrorCode.BOOKING_FAILED,
  ErrorCode.DAY_NOT_YET_OPEN_TO_BOOK,
  ErrorCode.CLASS_NOT_FOUND_ON_DAY
];

export const DAYS_OF_WEEK: Record<number, string> = {
  [1]: 'Monday',
  [2]: 'Tuesday',
  [3]: 'Wednesday',
  [4]: 'Thursday',
  [5]: 'Friday',
  [6]: 'Saturday',
  [7]: 'Sunday',
}

export const BOOKING_WINDOW_IN_WEEKS = 2;
export const RUN_NOW_WINDOW_IN_WEEKS = 1;
export const MINIMUM_TIME_ALLOWANCE_FROM_NOW_IN_HOURS = 48;

export const YYYY_MM_DD = 'YYYY-MM-DD';
export const HH_MM = 'HH:mm';
export const YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm';

export enum CategoryId {
  REFORMER_PILATES = 'reformer_pilates',
  STUDIO_FITNESS_CLASSES = 'studio_fitness_classes',
  CRECHE_KIDS_CLUB = 'creche_kids_club',
  OUTDOOR_POOL = 'outdoor_pool',
  INDOOR_POOL = 'indoor_pool',
  // MEMBER_EVENTS = 'member_events',
  // LITTLE_HOUSE_SWIM_SCHOOL = 'little_house_swim_school',
  // BEAUTY_TREATMENTS_ROOM_ONE = 'beauty_treatments_room_one',
  // WELLNESS_TREATMENTS_ROOM_TWO = 'wellness_treatments_room_two',
}

export const CATEGORY_TO_SERVICE_ID: Record<CategoryId, number> = {
  [CategoryId.STUDIO_FITNESS_CLASSES]: 2,
  [CategoryId.REFORMER_PILATES]: 3,
  [CategoryId.CRECHE_KIDS_CLUB]: 4,
  [CategoryId.INDOOR_POOL]: 5,
  [CategoryId.OUTDOOR_POOL]: 6,
  // [CategoryId.MEMBER_EVENTS]: 7,
  // [CategoryId.LITTLE_HOUSE_SWIM_SCHOOL]: 8,
  // UNKNOWN: 9
  // [CategoryId.BEAUTY_TREATMENTS_ROOM_ONE]: 10,
  // [CategoryId.WELLNESS_TREATMENTS_ROOM_TWO]: 11,
}
/*
Reformer Pilates
Studio & Fitness Classes
Jungle Gym/Soft Play
Creche & Kids' Club
Member Events
Outdoor pool
Indoor pool
Little House Swim School
Beauty Treatments - Room One
Wellness Treatments - Room Two
*/
export const CATEGORIES = {
  [CategoryId.REFORMER_PILATES]: 'Reformer Pilates',
  [CategoryId.STUDIO_FITNESS_CLASSES]: 'Studio & Fitness Classes',
  [CategoryId.CRECHE_KIDS_CLUB]: "Creche & Kids' Club",
  [CategoryId.OUTDOOR_POOL]: 'Outdoor pool',
  [CategoryId.INDOOR_POOL]: 'Indoor pool',
  // [CategoryId.MEMBER_EVENTS]: 'Member Events',
  // [CategoryId.LITTLE_HOUSE_SWIM_SCHOOL]: 'Little House Swim School',
  // [CategoryId.BEAUTY_TREATMENTS_ROOM_ONE]: 'Beauty Treatments - Room One',
  // [CategoryId.WELLNESS_TREATMENTS_ROOM_TWO]: 'Wellness Treatments - Room Two',
}

export enum ClassId {
  // Reformer Pilates
  REFORMER_STRENGTH = 'reformer_strength',
  REFORMER_ESSENTIALS = 'reformer_essentials',
  REFORMER_STRETCH = 'reformer_stretch',
  // Creche & Kids' Club
  CRECHE_KIDS_CLUB = 'creche_kids_club',
  // Outdoor pool
  OUTDOOR_POOL = 'outdoor_pool',
  // Indoor pool
  INDOOR_POOL = 'indoor_pool',
  // Studio & Fitness Classes
  SPIN = 'spin',
  TODDLER_GYM = 'toddler_gym',
  PILATES_SCULPT = 'pilates_sculpt',
  GROUP_TRAINING = 'group_training',
  RECRUITS = 'recruits',
  THE_ADVENTURERS = 'the_adventurers',
  TOT_BOP = 'tot_bop',
  NURSERY_RHYME_TIME = 'nursery_rhyme_time',
  PREGNANCY_YOGA = 'pregnancy_yoga',
  WAKE_UP_BOP = 'wake_up_bop',
  LITTLE_CHEFS = 'little_chefs',
  BABY_BOP = 'baby_bop',
  POSTNATAL_YOGA = 'postnatal_yoga',
  FUN_CLUB = 'fun_club',
  HAPPY_HOUSE = 'happy_house',
  IMAGINATION_STATION = 'imagination_station',
  CARiFiT = 'carifit',
  BALLET_BUNNIES = 'ballet_bunnies',
  PIZZA_MAKING = 'pizza_making',
  SPEEDY_SPIN = 'speedy_spin',
  BARRE_ESSENTIALS = 'barre_essentials',
  EARLY_YEARS_DRAMA = 'early_years_drama',
  SMOOTHIE_MAKING = 'smoothie_making',
  POSTPARTUM_FULL_BODY_TRAINING = 'postpartum_full_body_training',
  BABY_MASSAGE = 'baby_massage',
  ARTS_CRAFTS = 'arts_crafts',
  YOGA_VINYASA = 'yoga_vinyasa',
  YIN_YOGA = 'yin_yoga',
  BABY_YOGA = 'baby_yoga',
  BABY_BALLET = 'baby_ballet',
  BABY_SENSORY = 'baby_sensory',
  BABY_STORY_PLAY = 'baby_story_play',
  PARENT_AND_ME = 'parent_and_me',
  FOOTBALL = 'football',
  RUGBY_TOTS = 'rugby_tots',
  AMANDAS_ACTION_CLUB = 'amandas_action_club',
  YOGA_MUM_BABY = 'yoga_mum_baby',
  TODDLER_QUEST = 'toddler_quest',
  ADULT_BALLET = 'adult_ballet',
  MAT_PRECISION = 'mat_precision',
  TINY_DANCERS = 'tiny_dancers',
  PREPS = 'preps',
  MESSY_PLAY = 'messy_play',
  CIRCUS = 'circus',
  BUDNING_SCIENTISTS = 'budding_scientists',
  DISCO_DIVAS_DUDES = 'disco_divas_dudes',
  DODGEBALL = 'dodgeball',
  BARRE_STRENGTH = 'barre_strength',
  PILATES_PRECISION = 'pilates_precision',
  TROOPERS = 'troopers',
  TODDLER_HIP_HOP = 'toddler_hip_hop',
  QUEST = 'quest',
  CREATIVE_CLUB = 'creative_club',
}

export type ClassTag = 'Fitness' | 'Yoga' | 'Kids' | 'Pre-Natal' | 'Post-Natal';

export enum JobStep {
  ChildSelection = 'child_selection',
  AdultSelection = 'adult_selection',
  SlotSelection = 'slot_selection',
  ClassSelection = 'class_selection',
  CrecheForm = 'creche_form',
  TermsAndConditions = 'terms_and_conditions',
}

const DEFAULT_REFORMER_STEPS = [
  JobStep.ClassSelection,
  JobStep.AdultSelection,
];

const DEFAULT_NON_STANDARD_STEPS = [
  JobStep.ChildSelection,
  JobStep.AdultSelection,
  JobStep.SlotSelection,
  JobStep.TermsAndConditions,
];

const DEFAULT_CLASS_STEPS_ADULTS = [
  JobStep.ClassSelection,
  JobStep.AdultSelection,
  JobStep.TermsAndConditions,
];

const DEFAULT_CLASS_STEPS_KIDS = [
  JobStep.ClassSelection,
  JobStep.ChildSelection,
  JobStep.TermsAndConditions,
];

const DEFAULT_CLASS_STEPS_KIDS_AND_ADULTS = [
  JobStep.ClassSelection,
  JobStep.ChildSelection,
  JobStep.AdultSelection,
  JobStep.TermsAndConditions,
];

export const CLASSES: Record<ClassId, {
  name: string;
  aliases?: Array<string>;
  excludeTerms?: Array<string>;
  category: CategoryId;
  duration?: Duration;
  tags: Array<ClassTag>;
  steps: JobStep[];
}> = {
  // Reformer Pilates
  [ClassId.REFORMER_ESSENTIALS]: {
    name: 'Reformer Essentials',
    category: CategoryId.REFORMER_PILATES,
    tags: ['Fitness'],
    steps: DEFAULT_REFORMER_STEPS,
  },
  [ClassId.REFORMER_STRENGTH]: {
    name: 'Reformer Strength',
    category: CategoryId.REFORMER_PILATES,
    tags: ['Fitness'],
    steps: DEFAULT_REFORMER_STEPS,
  },
  [ClassId.REFORMER_STRETCH]: {
    name: 'Reformer Stretch',
    category: CategoryId.REFORMER_PILATES,
    tags: ['Fitness'],
    steps: DEFAULT_REFORMER_STEPS,
  },
  // Creche & Kids' Club
  [ClassId.CRECHE_KIDS_CLUB]: {
    name: 'Creche & Kids\' Club',
    category: CategoryId.CRECHE_KIDS_CLUB,
    duration: {
      max: 3,
      min: 1,
      granularity: 1,
      units: 'hours',
    },
    tags: ['Kids'],
    steps: [
      JobStep.ChildSelection,
      JobStep.SlotSelection,
      JobStep.CrecheForm,
      JobStep.TermsAndConditions,
    ],
  },
  // Outdoor pool
  [ClassId.OUTDOOR_POOL]: {
    name: 'Outdoor Pool',
    category: CategoryId.OUTDOOR_POOL,
    duration: {
      max: 1,
      min: .5,
      granularity: .5,
      units: 'hours',
    },
    tags: ['Fitness'],
    steps: DEFAULT_NON_STANDARD_STEPS,
  },
  // Indoor pool
  [ClassId.INDOOR_POOL]: {
    name: 'Indoor Pool',
    category: CategoryId.INDOOR_POOL,
    duration: {
      max: 1,
      min: .5,
      granularity: .5,
      units: 'hours',
    },
    tags: ['Fitness'],
    steps: DEFAULT_NON_STANDARD_STEPS,
  },
  // Studio & Fitness Classes
  [ClassId.SPIN]: {
    name: 'Spin',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    aliases: ['Proud Spin'],
    excludeTerms: ['Speedy'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.BARRE_ESSENTIALS]: {
    name: 'Barre Essentials',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    aliases: ['Proud Barre Essentials'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.TODDLER_GYM]: {
    name: 'Toddler Gym',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness', 'Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.PILATES_SCULPT]: {
    name: 'Pilates Sculpt',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    aliases: ['Proud Pilates Sculpt'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.GROUP_TRAINING]: {
    name: 'Group Training',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.PARENT_AND_ME]: {
    name: 'Parent & Me',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness', 'Kids'],
    aliases: ['Parent & Me Ballet'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.RECRUITS]: {
    name: 'Recruits',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness', 'Kids'],
    aliases: ['Tarka Recruits'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.THE_ADVENTURERS]: {
    name: 'The Adventurers',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.TOT_BOP]: {
    name: 'Baby Bop',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    aliases: ['Tot Bop'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.NURSERY_RHYME_TIME]: {
    name: 'Nursery Rhyme Time',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.PREGNANCY_YOGA]: {
    name: 'Pregnancy Yoga',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Yoga', 'Fitness', 'Pre-Natal'],
    aliases: ['Proud Pregnancy Yoga'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.WAKE_UP_BOP]: {
    name: 'Wake Up & Bop',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.LITTLE_CHEFS]: {
    name: 'Little Chefs',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.BABY_BOP]: {
    name: 'Baby Bop',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.POSTNATAL_YOGA]: {
    name: 'Postnatal Yoga',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Yoga', 'Fitness', 'Post-Natal'],
    aliases: ['Proud Postnatal Yoga'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.FUN_CLUB]: {
    name: 'Fun Club',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.HAPPY_HOUSE]: {
    name: 'Happy House',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.IMAGINATION_STATION]: {
    name: 'Imagination Station',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.CARiFiT]: {
    name: 'CARiFiT',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness', 'Kids'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.BALLET_BUNNIES]: {
    name: 'Ballet Bunnies',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },  
  [ClassId.PIZZA_MAKING]: {
    name: 'Pizza Making',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.SPEEDY_SPIN]: {
    name: 'Speedy Spin',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    aliases: ['Proud Speedy Spin'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.EARLY_YEARS_DRAMA]: {
    name: 'Early Years Drama',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.SMOOTHIE_MAKING]: {
    name: 'Smoothie Making',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.POSTPARTUM_FULL_BODY_TRAINING]: {
    name: 'Postpartum Full Body Training',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness', 'Post-Natal'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.BABY_MASSAGE]: {
    name: 'Baby Massage',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.ARTS_CRAFTS]: {
    name: 'Arts & Crafts',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },  
  [ClassId.YOGA_VINYASA]: {
    name: 'Yoga Vinyasa',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Yoga', 'Fitness'],
    aliases: ['Proud Yoga Vinyasa'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.YIN_YOGA]: {
    name: 'Yin Yoga',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Yoga'],
    aliases: ['Yin Yoga'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.BABY_YOGA]: {
    name: 'Baby Yoga',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Yoga', 'Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.BABY_BALLET]: {
    name: 'Baby Ballet',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.FOOTBALL]: {
    name: 'Football',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness', 'Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.RUGBY_TOTS]: {
    name: 'Rugby Tots',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness', 'Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.AMANDAS_ACTION_CLUB]: {
    name: "Amanda's Action Club",
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.YOGA_MUM_BABY]: {
    name: 'Yoga Mum & Baby',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Yoga', 'Kids', 'Post-Natal'],
    aliases: ['Proud Yoga Mum & Baby'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.TODDLER_QUEST]: {
    name: 'Toddler Quest',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.ADULT_BALLET]: {
    name: 'Adult Ballet',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.MAT_PRECISION]: {
    name: 'Mat Precision',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    aliases: ['Proud Mat Precision'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.TINY_DANCERS]: {
    name: 'Tiny Dancers',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.PREPS]: {
    name: 'Preps',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.BARRE_STRENGTH]: {
    name: 'Barre Strength',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    aliases: ['Proud Barre Strength'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.MESSY_PLAY]: {
    name: 'Messy Play',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.CIRCUS]: {
    name: 'Circus',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.BUDNING_SCIENTISTS]: {
    name: 'Budding Scientists',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.DISCO_DIVAS_DUDES]: {
    name: 'Disco Divas & Dudes',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.DODGEBALL]: {
    name: 'Dodgeball',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness', 'Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.PILATES_PRECISION]: {
    name: 'Pilates Precision',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    aliases: ['Proud Pilates Precision'],
    steps: DEFAULT_CLASS_STEPS_ADULTS,
  },
  [ClassId.TROOPERS]: {
    name: 'Troopers',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    aliases: ['Tarka Troopers'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.TODDLER_HIP_HOP]: {
    name: 'Toddler Hip Hop',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.QUEST]: {
    name: 'Quest',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.CREATIVE_CLUB]: {
    name: 'Creative Club',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.BABY_SENSORY]: {
    name: 'Baby Sensory',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
  [ClassId.BABY_STORY_PLAY]: {
    name: 'Baby Story Play',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Kids'],
    steps: DEFAULT_CLASS_STEPS_KIDS,
  },
}

export const CLASSES_BY_CATEGORY = _.groupBy(Object.entries(CLASSES).map(([id, profile]) => ({
  id,
  ...profile,
})), 'category');

export enum ClassGroup {
  REFORMER_ANY = 'reformer_any',
  YOGA_ADULT_ANY = 'yoga_adult_any',
  BARRE_ADULT_ANY = 'barre_adult_any',
}

export const CLASS_GROUPS: Record<ClassGroup, {
  name: string;
  category: CategoryId;
  tags: string[];
  classIds: ClassId[];
}> = {
  [ClassGroup.REFORMER_ANY]: {
    name: 'Any Reformer',
    category: CategoryId.REFORMER_PILATES,
    tags: ['Fitness'],
    classIds: [
      ClassId.REFORMER_ESSENTIALS,
      ClassId.REFORMER_STRENGTH,
      ClassId.REFORMER_STRETCH,
    ],
  },
  [ClassGroup.YOGA_ADULT_ANY]: {
    name: 'Any Adult Yoga',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    classIds: [
      ClassId.YOGA_VINYASA,
      ClassId.YIN_YOGA,
    ],
  },
  [ClassGroup.BARRE_ADULT_ANY]: {
    name: 'Any Adult Barre',
    category: CategoryId.STUDIO_FITNESS_CLASSES,
    tags: ['Fitness'],
    classIds: [
      ClassId.BARRE_STRENGTH,
      ClassId.BARRE_ESSENTIALS,
    ],
  },
}

export enum BillingProduct {
  CALENDAR_SYNC = 'calendar_sync',
  AUTOMATED_BOOKINGS = 'automated_bookings',
  SERVICE_FEE = 'service_fee',
}

export enum AccountDisableReason {
  NO_PAYMENT_METHOD = 'no_payment_method',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
}

export enum JHPortal {
  JAEGOS = 'jaegos',
  JESSES = 'jesses',
}

export enum PGEvent {
  APP_VIEWED = 'app_viewed',
  CALENDAR_SYNC_TOGGLED = 'calendar_sync_toggled',
  AUTO_BOOKER_TOGGLED = 'auto_booker_toggled',
  JOB_CREATED = 'job_created',
  JOB_DELETED = 'job_deleted',
  JOB_RUN_NOW = 'job_run_now',
  BOOKER_JOB_FAILED = 'booker_job_failed',
  BOOKER_JOB_SUCCESS = 'booker_job_success',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_DEACTIVATED = 'account_deactivated',
  ACCOUNT_ACTIVATED = 'account_activated',
  SUBSCRIPTION_STARTED = 'subscription_started',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
  BOOKING_CREATED = 'booking_created',
  BOOKING_CANCELED = 'booking_canceled',
  INVITE_ACCEPTED = 'invite_accepted',
  INVITE_SENT = 'invite_sent',
  INVITE_RECEIVED = 'invite_received',
}
